// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-service-category-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/calendula/ServiceCategoryTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-service-category-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-service-item-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/calendula/ServiceItemTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-service-item-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-team-member-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/calendula/TeamMemberTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-team-member-template-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-beauty-products-jsx": () => import("./../../../src/pages/beauty-products.jsx" /* webpackChunkName: "component---src-pages-beauty-products-jsx" */),
  "component---src-pages-booking-cancellation-policy-jsx": () => import("./../../../src/pages/booking-cancellation-policy.jsx" /* webpackChunkName: "component---src-pages-booking-cancellation-policy-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-promotions-jsx": () => import("./../../../src/pages/promotions.jsx" /* webpackChunkName: "component---src-pages-promotions-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */)
}

